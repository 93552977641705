<template>
  <div class="report-records-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100]"
                      @change="getRecords"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="datatable.queries.search"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <button
                    class="ml-1 btn btn-primary btn-sm"
                    @click="onQueryChange('search')"
                  >
                    <i class="uil uil-search-alt"></i>
                  </button>
                  <button
                    class="ml-1 btn btn-primary btn-sm"
                    @click="refresh"
                  >
                    <i class="uil uil-refresh"></i>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!loading">
              <div class="table-responsive mb-0">
                <b-table
                  :items="records"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(sent_timestamp)="data">
                    {{ data.item.sent_timestamp | moment('MM-DD-YYYY hh:mm:ss A') }}
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalRecords }} records
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalRecords"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'id', label: '#', sortable: false },
          { key: 'call_start', sortable: false },
          { key: 'call_end', sortable: false },
          { key: 'call_type', sortable: false },
          { key: 'call_source', sortable: false },
          { key: 'call_destination', sortable: false },
          { key: 'duration', sortable: false },
          { key: 'location_name', sortable: false },
          { key: 'sub_account_name', sortable: false },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      records: [],
      loading: false,
    }
  },

  computed: {
    totalRecords() {
      return this.records.length;
    }
  },

  mounted() {
    this.getRecords()
  },

  methods: {
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getRecords()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getRecords()
    },

    onQueryChange(type = undefined) {
      this.$nextTick(() => {
        if (type === 'search')
        {
          this.datatable.queries.page = 1;
        }
        this.getRecords()
      });
    },

    getRecords() {
      this.loading = true
      this.records = [];

      this.$store.dispatch('report/getAllBdr', this.datatable.queries)
        .then((res) => {
          this.records = res
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    refresh() {
      this.datatable.queries.search = null
      this.getRecords();
    }
  },
}
</script>